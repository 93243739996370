/* eslint-disable no-console */
import { setComponents } from '@storyblok/react';

import n4Components from 'components/bloks/n4';
import homeComponents from 'components/bloks/n4/home';
import GenericStory from 'components/layout/generic_story';
import N4Layout from 'components/layout/n4_layout';
import logger from 'lib/logger';
import loadStory from 'lib/page_helpers/story';

const Story = ({ folder, story, preview, ...props }) => {
  setComponents({ ...n4Components, ...homeComponents });
  return <GenericStory folder={folder} story={story} preview={preview} {...props} />;
};
Story.getLayout = (children, pageProps) => <N4Layout {...pageProps}>{children}</N4Layout>;

const getStaticProps = async ({ params }) => {
  const log = logger({ category: `stories/${params.folder}${params.folder === '' ? '' : '/'}${params.slug}` });
  log.info('getStaticProps: %o', params);
  const results = await loadStory({ ...params, folder: 'root', slug: 'index', origin: 'stories', version: 'published', log });
  return results;
};

const getStaticPaths = async () => ({
  paths: [{ params: { folder: 'root', slug: 'index', variant: ['A'] } }, { params: { folder: 'root', slug: 'index', variant: ['B'] } }],
  fallback: 'blocking',
});

export { getStaticPaths, getStaticProps };

export default Story;
